import HomeContent from "section/HomeContent";
import Burve from "section/Burve";
import ScrollCarousel from "section/ScrollCarousel";
import Footer from "components/Footer";
import Chat from "section/Chat";
// import Overview from "section/Overview";
import "./index.scss";
import Layout from "components/Layout";
import LatestUpdates from "components/LatestUpdates";

function HomePage() {
  return (
    <Layout>
      <LatestUpdates />
      <div className="page_box">
        <HomeContent />
        {/* <Overview /> */}
        <div id="chat-page">
          <Chat />
        </div>
        <ScrollCarousel />
        <Burve />
        <Footer />
      </div>
    </Layout>
  );
}

export default HomePage;
